<template>
  <div class="px-4 py-5 space-y-6 sm:p-12">
    <img class="text-center w-1/3 m-auto" src="https://media.publit.io/file/1240425_check_email_mail_message_verification_icon.png"  />
    <h2 class="mt-8 text-xl text-center font-extrabold text-gray-900">
      Thank you for Subscribing!
    </h2>
    <p class="mt-2 text-sm text-gray-600 text-center">
      Thank you for subscribing to our mailing list! If you have any question, feel free to reach us at <a href="mailto:support@infusedaddons.com">support@infusedaddons.com</a>!
    </p>
  </div>
</template>